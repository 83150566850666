<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">基本信息</span>
      </el-col>
    </el-row>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex" :rules="rules">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="申请类型" :class="$i18n.locale" prop="applyType">
              <el-select v-model="form.applyType" clearable :placeholder="$t('page.selectPlaceholder')" :disabled="Boolean($route.query.applyId)">
                <el-option label="运营申请" value="1" />
                <el-option label="计划释放" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台" :class="$i18n.locale" prop="platformCode">
              <el-select
                v-model="form.platformCode"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                @change="platformchange"
              >
                <el-option
                  v-for="item in platforoptions"
                  :key="item.platformCode"
                  :label="item.platformName"
                  :value="item.platformCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点" prop="siteCode">
              <el-select v-model="form.siteCode" clearable filterable @change="$forceUpdate()">
                <el-option v-for="item in siteoptions" :key="item.id" :label="item.siteName" :value="item.siteName" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库" :class="$i18n.locale" prop="warehouseIdList">
              <el-select
                v-model="form.warehouseIdList"
                clearable
                filterable
                multiple
                collapse-tags

                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="STYLE" :class="$i18n.locale" prop="styleList">
              <el-select
                v-model="form.styleList"
                value-key="id"
                clearable
                filterable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(item,index) in styleOptions"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="COLOR" :class="$i18n.locale">
              <el-select
                v-model="form.colorList"
                value-key="id"
                clearable
                filterable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIZE" :class="$i18n.locale">
              <el-select
                v-model="form.sizeList"
                value-key="id"
                clearable
                filterable
                multiple
                collapse-tags
              >
                <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="tableDatasLoading" @click="queryClick()">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>

        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="2">
        <span style="padding-left:25px">明细</span>
      </el-col>
      <el-button @click="download">模板下载</el-button>
      <el-button @click="handleClick">导入</el-button>
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
      @selection-change="SelectionChange"
    >
      <el-table-column type="selection" width="80" align="center" />
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center" />
      <el-table-column prop="warehouseName" label="仓库" width="120" />
      <el-table-column prop="style" label="Style" min-width="150" sortable />
      <el-table-column prop="color" label="Color" min-width="150" sortable />
      <el-table-column prop="size" label="Size" min-width="100" sortable />
      <!-- boxNumber -->
      <el-table-column prop="boxNumber" min-width="110px" label="双/箱" />
      <el-table-column prop="releasePairs" min-width="110px" label="在库" />
      <el-table-column prop="lockPairs" min-width="110px" label="锁定" />
      <el-table-column min-width="120px" label="可申请数量">
        <template slot-scope="scope">
          {{ scope.row.canApply||scope.row.releasePairs }}
        </template>
      </el-table-column>
      <el-table-column prop="applyPairs" min-width="120px" label="申请数量">
        <template slot-scope="scope">
          <el-input v-model="scope.row.applyPairs" type="text" oninput="value=value.replace(/[^0-9.]/g,'')" @input="input(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <div v-if="$route.query.applyId" class="mt-4">
      <el-row class="grid-content bg-purple-dark">
        <el-col :span="20">
          <span style="padding-left:25px">历史申请记录</span>
        </el-col>
      </el-row>
      <el-table
        ref="tableDatas"
        :data="recordsTableDatas"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="400px"
      >
        <el-table-column :label="$t('page.No')" type="index" width="80" align="center" />
        <el-table-column prop="createByName" label="申请人" min-width="150" />
        <el-table-column prop="createTime" label="申请时间" min-width="150" />
        <el-table-column prop="applyPairs" label="申请数量" min-width="150" />
        <el-table-column prop="modifyByName" min-width="150px" label="审批人" />
        <el-table-column prop="modifyTime" min-width="150px" label="审批时间" />
      </el-table>
    </div>
    <el-row class="mt-4">
      <el-col :offset="11" :span="2"><el-button type="primary" @click="submit">提交</el-button></el-col>
    </el-row>
    <input
      ref="imFile"
      type="file"
      style="display: none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="importFile(this)"
    >
    <!--错误信息提示-->
    <el-dialog :visible.sync="errorDialog" title="提示" width="30%">
      <span>{{ errorMsg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="errorDialog=false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import store from '@/store'
import dayjs from 'dayjs'
import { findSafet, findSafeStoreHistory, safeStoreInfo, findSafeStore, findSafeStoreDetail, safeStyle } from '@/api/safestock'
import { queryTerraceList, queryWarehouseList, querySizeList, queryColorList, listByPlatform } from '@/api/scm-api'
export default {
  data() {
    return {
      errorDialog: false,
      errorMsg: '',
      fullscreenLoading: false,
      recordsTableDatas: [],
      tableDatasLoading: false,
      tableDatas: [],
      form: {
        applyType: '',
        platformCode: '',
        siteCode: '',
        warehouseIdList: [],
        styleList: []
      },
      platforoptions: [],
      siteoptions: [],
      warehouseOption: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      rules: {
        applyType: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ],
        platformCode: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ],
        siteCode: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ],
        warehouseIdList: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ],
        styleList: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ]
      },
      multipleTableSelection: [],
      basicInfo: {}

    }
  },
  computed: {
    addParams() {
      this.getWarehouseId()
      return Object.assign({ safeDetailvo: this.multipleTableSelection, safevo: Object.assign(this.form, { createById: store.getters.userId,
        createByName: store.getters.name }) })
    },
    editParams() {
      this.getWarehouseId()
      return Object.assign({ safeDetailvo: this.multipleTableSelection, safevo: Object.assign(this.form, this.basicInfo) })
    }
  },
  created() {

  },
  mounted() {
    this._queryTerraceList()
    this._querySiteList()
    this._queryWarehouseList()
    this._queryStyleList()
    this._queryColorList()
    this._querySizeList()
    if (this.$route.query && this.$route.query.applyId) {
      // 回显 历史记录
      const { applyId, historyId } = this.$route.query
      this._findSafeStoreDetail(applyId, historyId, 1)
      this._findSafeStoreHistory(applyId)
      this._queryBasicInfo(applyId)
    }
  },
  methods: {
    getWarehouseId() {
      this.multipleTableSelection = this.multipleTableSelection.map(item => {
        const { id } = this.warehouseOption.find(e => e.warehouseName === item.warehouseName) || []
        return { ...item, warehouseId: id }
      })
    },
    analyzeData: function(data) { // 此处可以解析导入数据
      return data
    },
    importFile: function() { // 导入excel
      this.fullscreenLoading = true
      const obj = this.$refs.imFile
      if (!obj.files) {
        this.fullscreenLoading = false
        return
      }
      var f = obj.files[0]
      var reader = new FileReader()
      const $t = this
      reader.onload = function(e) {
        var data = e.target.result
        if ($t.rABS) {
          $t.wb = XLSX.read(btoa(this.fixdata(data)), { // 手动转化
            type: 'base64'
          })
        } else {
          $t.wb = XLSX.read(data, {
            type: 'binary'
          })
        }
        const json = XLSX.utils.sheet_to_json($t.wb.Sheets[$t.wb.SheetNames[0]])
        $t.dealFile($t.analyzeData(json)) // analyzeData: 解析导入数据
      }
      if (this.rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },
    dealFile: function(data) { // 处理导入的数据
      let arr = []
      this.$refs.imFile.value = ''
      this.fullscreenLoading = false
      if (!data.length) {
        this.errorDialog = true
        this.errorMsg = '请导入正确信息'
        return
      }

      arr = data.map(item => {
        return {
          color: item.Color, size: item.Size, style: item.Style,
          sku: item.sku,
          warehouseName: item.仓库, releasePairs: item.可申请数量,
          applyPairs: item.申请数量,
          boxNumber: item.箱双,
          lockPairs: item.锁定,
          no: item.序号 }
      })
      const erroArr = []
      if (arr.filter(item => item.applyPairs % item.boxNumber !== 0).length) {
        arr.filter(item => item.applyPairs % item.boxNumber !== 0).map(item => erroArr.push(item.no))
        this.errorDialog = true
        this.errorMsg = '表格序号 ' + String(erroArr) + ' 导入无效，请检查！'
        return
      }
      this.tableDatas = arr
    },
    handleClick() {
      this.$refs.imFile.click()
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        }
        if (j === 'releasePairs') {
          return v.canApply || v.releasePairs
        } else {
          return v[j]
        }
      }))
    },
    download() {
      import('@/vendor/Export2Excel').then(excel => {
        const header = [this.$t('page.No'), this.$t('page.warehouse'), 'sku', 'Style', 'Color', 'Size', this.$t('title.Unit'), this.$t('title.lockPairs'), this.$t('title.releasePairs'), this.$t('title.applyPairs')]
        const filterVal = ['no', 'warehouseName', 'sku', 'style', 'color', 'size', 'boxNumber', 'lockPairs', 'releasePairs', 'applyPairs']
        const data = this.formatJson(filterVal, this.tableDatas)
        excel.export_json_to_excel({
          header,
          data,
          filename: `An List ${dayjs(new Date()).format('YYYY/MM/DD')}`
        })
      })
    },
    async _findSafeStoreDetail(applyId, historyId, type) {
      try {
        this.tableDatasLoading = true
        const { code, datas } = await findSafeStoreDetail(applyId, historyId, type)
        if (code === 0) {
          this.tableDatas = datas
          this.tableDatasLoading = false
          // 默认全选
          if (this.tableDatas) {
            this.$nextTick(() => {
              this.tableDatas.forEach(item => {
                this.$refs.multipleTable.toggleRowSelection(
                  item,
                  true
                )
              })
            })
            this.multipleTableSelection = this.$refs.multipleTable.selection
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    },
    async _queryBasicInfo(id) {
      const { code, datas } = await findSafeStore(id)
      this.basicInfo = datas
      const { applyType, platformCode, siteCode, warehouseName, style, color, size } = datas
      const { id: warehouseId } = this.warehouseOption.find(item => item.warehouseName === warehouseName) || []
      code === 0 ? this.form = { applyType, platformCode, siteCode, warehouseIdList: [warehouseId], style, color, size, styleList: [] } : ''
      !this.$route.query.applyId && this.queryClick()
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.multipleTableSelection.length === 0) {
            this.$message.warning('请至少选中一条数据')
            return
          }
          if (this.multipleTableSelection.some(item => item.applyPairs > item.canApply || item.applyPairs > item.releasePairs)) {
            this.$message.warning('存在申请数据大于可申请数据,请核对')
            return
          }
          if (this.$route.query.applyId) {
            const { code, msg } = await safeStoreInfo(this.editParams)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$refs.multipleTable.clearSelection()
              this.$router.push({
                path: '../',
                append: true
              })
            }
          } else {
            const { code, msg } = await safeStoreInfo(this.addParams)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$refs.multipleTable.clearSelection()
              this.$router.push({
                path: '../',
                append: true
              })
            }
          }
        }
      })
    },
    async _findSafeStoreHistory(id) {
      const { datas, code } = await findSafeStoreHistory(id)
      code === 0 ? this.recordsTableDatas = datas : ''
    },
    input(row) {
      (row.applyPairs > 0 && row.applyPairs <= row.canApply) || (row.applyPairs > 0 && row.applyPairs <= row.releasePairs) ? this.$refs.multipleTable.toggleRowSelection(row, true)
        : this.$refs.multipleTable.toggleRowSelection(row, false)
    },
    SelectionChange(val) {
      this.multipleTableSelection = val
    },
    queryClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.tableDatasLoading = true
            const { datas, code } = await findSafet(this.form)
            if (code === 0) {
              this.tableDatas = datas.map(item => {
                delete item.applyPairs
                return { ...item, canApply: item.releasePairs - item.lockPairs, applyPairs: item.releasePairs - item.lockPairs }
              }
              )
              this.tableDatasLoading = false
              // 默认全选
              if (this.tableDatas) {
                this.$nextTick(() => {
                  this.tableDatas.forEach(item => {
                    this.$refs.multipleTable.toggleRowSelection(
                      item,
                      true
                    )
                  })
                })
                this.multipleTableSelection = this.$refs.multipleTable.selection
              }
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.tableDatasLoading = false
          }
        }
      })
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
    },
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platforoptions = datas
    },
    async _querySiteList(id) {
      const { datas } = await listByPlatform({ sellPlatformId: id })
      this.siteoptions = datas
    },
    platformchange() {
      const { id } = this.platforoptions.find(item => item.platformCode === this.form.platformCode) || []
      this._querySiteList(id)
      this.form.siteCode = ''
    },
    // 仓库
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    },
    // size
    async _querySizeList() {
      const { datas } = await querySizeList()
      this.sizeOptions = datas
    },
    // style
    async _queryStyleList() {
      const { datas } = await safeStyle()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      const { datas } = await queryColorList()
      this.colorOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
.style{
  /deep/.el-input__inner {
    width:260px!important;
  }
}
</style>
